import { parse } from "content-disposition-header";
import format from "date-fns/format";
import fileDownload from "js-file-download";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { UnreachableCaseError } from "./errors";
import { campaignTasksClient, campaignsClient, documentsClient } from "./services";
import { WaitKeys, debounce } from "@/helpers";
import { IDocumentUrls, IEntity, ISettings, Role, ICampaignTasksExportFilter, ICampaignReferences, IBrandQuality, IBrand, ITouchPoint, ValidationStatus } from "@/models";
import { RouteNames } from "@/router";
import { settings } from "@/settings";
import UserAvatar from "@/components/shared/userAvatar/UserAvatar";
import NotificationsWrapper from "@/components/shared/notificationsWrapper/NotificationsWrapper.vue";
import { analyticsService } from "./services";
import { store } from "./store";
import { PrSelectOption } from "@pernod-ricard/design-system/dist/types/components/pr-select/pr-select-model";
import axios from "axios";
import { PrTreeOption } from "@pernod-ricard/design-system/dist/types/components/pr-tree/pr-tree-model";
import { PrTreeselect } from "@pernod-ricard/design-system/dist/components";

@Component({
    components: {
        "user-avatar": UserAvatar,
        "notifications-wrapper": NotificationsWrapper,
    },
})
export default class App extends Vue {
    public routeNames = RouteNames;

    public userEntities: IEntity[] = [];

    public availableBrandQualitiesOptions: PrTreeOption[] = [];

    public isHeaderExpanded = false;

    public isExportModalOpened = false;

    public documentUrls: IDocumentUrls = {
        faq: null,
        touchPoints: null,
        userGuide: null,
        campaignNameBuilderV3: null,
        lastReleaseNote: null,
        exceedraTaxonomy: null,
        businessGlossary: null,
        helpDesk: null,
    };

    public isExportLoading = false;

    public request = null;

    public tasksExportFilter: ICampaignTasksExportFilter;

    public quartersSelected: string[] = [];

    public fiscalYear = "";

    public entityToExport = "";

    public isInformUserOpen = true;

    // Filters for export all tasks
    public $refs: Refs;

    public references: ICampaignReferences;

    public searchBrandQualityIds: number[] = [];

    public searchTouchPointsIds: number[] = [];

    public brandQualityIds = [];

    public touchPointIds = [];

    public brandQualityFilter: string = null;

    public touchPointFilter: string = null;

    public windowHeight: number = null;

    public availableTouchPoints: PrTreeOption[] = [];

    public readonly debouncedTouchPointSearch = debounce(this.onTouchPointSearch, 500);

    public readonly debouncedBrandQualitySearch = debounce(this.onBrandQualitySearch, 200);

    // This key CAN be set to false to avoid building the touchpoints tree
    protected usesTouchPointTree = true;

    // This key CAN be set to false to avoid building the brandQuality tree
    protected usesBQTree = true;

    // end filters

    private localStorageSelectedEntityKey = "selectedEntityKey";

    private storeMutationsUnsub: () => void = null;

    constructor() {
        super();
        this.references = {
            maxDate: null,
            minDate: null,
            brandQualityIds: [],
            entityIds: [],
            publisherIds: [],
            purchaseOrderIds: [],
            sourceIds: [],
            touchPointIds: [],
            vendorIds: [],
            validationStatus: [],
            regionsIds: [],
            completedTasksNumber: null,
            touchpointCategoryNames: [],
        };

        this.tasksExportFilter = {
            periods: [],
            entityId: 0,
            brandQualityIds: [],
            touchPointIds: [],
            validationStatus: [ValidationStatus.Validated],
        };
    }

    public get exportDisabled(): boolean {
        return (this.fiscalYear === "" || this.fiscalYear === null)
        || this.entityToExport === "" || this.quartersSelected.length === 0
        || this.searchBrandQualityIds.length === 0 || this.searchTouchPointsIds.length === 0
        || this.tasksExportFilter.validationStatus.length === 0;
    }

    public get isAuthenticated(): boolean {
        return store.getters["account/isAuthenticated"];
    }

    public get email(): string {
        return store.state.account.user?.email;
    }

    public get username(): string {
        return store.state.account.user?.name ?? "";
    }

    public get isAdmin(): boolean {
        return store.state.account.user?.roleId === Role.Administrator;
    }

    public get isCountryAdmin(): boolean {
        return store.state.account.user?.roleId === Role.CountryAdministrator;
    }

    public get isDataCollector(): boolean {
        return store.state.account.user?.roleId === Role.DataCollector;
    }

    public get isDataSteward(): boolean {
        const roleId = store.state.account.user?.roleId;
        return roleId === Role.LocalDataSteward;
    }

    public get canAccessDataCollection(): boolean {
        return this.isAdmin || this.isDataSteward || this.isDataCollector || this.isCountryAdmin;
    }

    public get canAccessDataValidation(): boolean {
        return this.isAdmin || this.isDataSteward || this.isCountryAdmin;
    }

    public get canDisplayUser(): boolean {
        return this.isAuthenticated && !this.$wait.is(WaitKeys.userData) && !!this.email;
    }

    public get getUserRoleName(): string {
        const roleId = store.state.account.user?.roleId;
        if (roleId) {
            return this.getRoleName(roleId);
        }
        else {
            return "";
        }
    }

    public get isValidationActive(): boolean {
        return this.$route.matched
            .filter(route => route.meta.menuItem)
            .map(route => route.meta.menuItem).includes("data-validation");
    }

    public get isCollectionActive(): boolean {
        return this.$route.matched
            .filter(route => route.meta.menuItem)
            .map(route => route.meta.menuItem).includes("data-collection");
    }

    public get isManageActive(): boolean {
        return this.$route.matched
            .filter(route => route.meta.menuItem)
            .map(route => route.meta.menuItem).includes("manage");
    }

    public get isAdministrationActive(): boolean {
        return this.$route.matched
            .filter(route => route.meta.menuItem)
            .map(route => route.meta.menuItem).includes("settings");
    }

    public get isUserSettingsActive(): boolean {
        return this.$route.matched
            .filter(route => route.meta.menuItem)
            .map(route => route.meta.menuItem).includes("user-settings");
    }

    public get userEntitiesFiltered(): IEntity[] {
        let entities: IEntity[] = [];

        if (store.state.account.user?.roleId === Role.Administrator) {
            entities = store.state.data.entities.filter(e => e.isUsed);
        }
        else {
            entities = store.state.data.entities.filter(e => store.state.account.userEntities.some(ue => e.isUsed && ue === e.entityId));
        }

        this.initializeSelectedEntity(entities);

        return entities;
    }

    public get isUserAssociatedUSA(): boolean {
        return this.userEntities.some(x => x.mdhCode === "MU_106");
    }

    public get settings(): ISettings {
        return store.state.data.featureFlags?.settings;
    }

    public get selectedEntity(): IEntity {
        return store.state.data.entities.find(e => e.entityId === store.state.account.selectedEntityId);
    }

    public get dateFiscal(): PrSelectOption[] {
        const today = new Date();
        const year = today.getFullYear();

        return [{
            type: "option",
            value: `${year-3}`,
            label: `FY${(year - 2) % 100}`,
        },
        {
            type: "option",
            value: `${year-2}`,
            label: `FY${(year - 1) % 100}`,
        },
        {
            type: "option",
            value: `${year-1}`,
            label: `FY${year % 100}`,
        },
        {
            type: "option",
            value: `${year}`,
            label: `FY${(year + 1) % 100}`,
        }];
    }

    public get quarters(): PrSelectOption[] {
        if (this.fiscalYear === "") {
            return [];
        }
        const year = parseInt(this.fiscalYear, 10);
        return [{
            type: "option",
            value: `${year}-07-01;${year+1}-06-30`,
            label: "Full Year",
        },
        {
            type: "option",
            value: `${year}-07-01;${year}-09-30`,
            label: `Q1 (July 1st, ${year} - Sept. 30th, ${year})`,
        },
        {
            type: "option",
            value: `${year}-10-01;${year}-12-31`,
            label: `Q2 (Oct. 1st, ${year} - Dec. 31th, ${year})`,
        },
        {
            type: "option",
            value: `${year + 1}-01-01;${year + 1}-03-31`,
            label: `Q3 (Jan. 1st, ${year + 1} - Mar. 31th, ${year + 1})`,
        },
        {
            type: "option",
            value: `${year + 1}-04-01;${year + 1}-06-30`,
            label: `Q4 (Apr. 1st, ${year + 1} - Jun. 30th, ${year + 1})`,
        }];
    }

    public get exportableEntities(): PrSelectOption[] {
        return this.userEntitiesFiltered.map<PrSelectOption>(e => ({ type: "option", value: e.entityId.toString(), label: e.name }));
    }

    // getters for export all tasks

    public get topSelectedTouchpoints(): number[] {
        const currentTPTree = [...this.availableTouchPoints];
        let topIds = [];
        for (const node of currentTPTree) {
            topIds = topIds.concat(this.findTopNode(node));
        }

        return topIds;
    }

    public get topSelectedBQ(): IBrandQualityChip[] {
        const currentTree = [...this.availableBrandQualitiesOptions];
        const topBQ: IBrandQualityChip[] = [];

        for (const brand of currentTree) {
            if (brand.checked && !brand.indeterminate) {
                topBQ.push({id: brand.id, isBrandLevel: true});
            }
            else if (brand.children) {
                brand.children.forEach(bq => {
                    if (bq.checked) {
                        topBQ.push({id: bq.id, isBrandLevel: false});
                    }
                });
            }
        }

        return topBQ;
    }


    public get touchPointsOption(): PrSelectOption[] {
        let tps = store.state.data.touchPoints.filter(tp => this.references.touchPointIds?.includes(tp.touchPointId));
        if (this.touchPointFilter?.length > 0) {
            tps = tps
                .filter(tp => this.searchTouchPointsIds.some(tpId => tp.touchPointId.toString() === tpId.toString()) ||  tp.name.toLocaleLowerCase().includes(this.touchPointFilter));
        }

        return tps
            .map(tp => ({type:"option", value: tp.touchPointId.toString(), label: tp.name}));
    }

    public get availableBrandQualitiesOption(): PrSelectOption[] {
        let bqs = this.availableBrandQualities;
        if (this.brandQualityFilter?.length > 0) {
            bqs = bqs
            .filter(bq => this.searchBrandQualityIds.some(bqId => bq.brandQualityId.toString() === bqId.toString()) ||  bq.name.toLocaleLowerCase().includes(this.brandQualityFilter));
        }
        return bqs.map(bq => ({type:"option", value: bq.brandQualityId.toString(), label: bq.name}));
    }

    public get availableBrandQualities(): IBrandQuality[] {
        if (this.references == null || this.references.brandQualityIds.length === 0) {
            return [];
        }

        return store.state.data.brandQualities
                    .filter(bq => this.references.brandQualityIds.includes(bq.brandQualityId));
    }

    public get validationStatusOptions(): PrSelectOption[] {
        return Object.values(ValidationStatus).map(vs => ({type:"option", value: vs.toString(), label: this.getValidationStatusName(vs)}));
    }


    // end getters for export all tasks

    // Hook
    @Watch("$route")
    public onRouteChange(to: Route): void {
        let title = settings.webSite.title ?? "";
        if (to.meta.title?.length > 0) {
            title = `${to.meta.title} - ${title}`;
        }

        document.title = title;
    }

    @Watch("fiscalYear")
    public onFiscalYearChange(): void {
        this.quartersSelected = [];
    }

    public async mounted(): Promise<void> {
        this.isHeaderExpanded = false;
        this.windowHeight = window.innerHeight;

        this.storeMutationsUnsub = store.subscribe(async mutation => {
            if (mutation.type === "data/SET_ENTITIES") {
                this.userEntities = store.getters["account/userEntities"];
            }

            if (mutation.type === "account/LOAD_ACCOUNT") {
                // Load documents only when user is logged in
                this.documentUrls = await documentsClient.getUrls();
            }
        });
    }

    public onBurgerMenuClick(event: any): void {
        if (event.target.className === "header sticky hydrated" && event.x > 15 && event.x < 35 && event.y > 15 && event.y < 35) {
            this.isHeaderExpanded = true;
        }
    }

    public closeHeader(): void {
        this.isHeaderExpanded = false;
    }

    public onOpenCloseHeader(): void {
        this.isHeaderExpanded = !this.isHeaderExpanded;
    }

    public onSelectEntity(entity: IEntity): void {
        this.selectEntity(entity);
        if (this.isValidationActive) {
            this.redirect(RouteNames.campaignValidation);
        }
        else if (this.isCollectionActive) {
            this.redirect(RouteNames.campaignsBoard);
        }
    }

    public beforeDestroy(): void {
        this.storeMutationsUnsub();
    }

    public logout(): void {
        store.dispatch("account/logout");
    }

    public openExportModal(): void {
        this.isExportModalOpened = true;
    }

    public async onExportEntityChange(newEntity: number): Promise<void> {

        this.references = await campaignsClient.getCampaignsReferences({entityId: newEntity});
        this.searchBrandQualityIds = [];
        this.brandQualityFilter = "";
        this.touchPointFilter = "";
        this.searchTouchPointsIds = [];
        this.tasksExportFilter.validationStatus = [ValidationStatus.Validated];
        this.quartersSelected = [];
        this.fiscalYear = "";
        this.availableTouchPoints = this.buildTouchPointsTree();
        this.refreshTouchPointsTreeSelect();
        this.availableBrandQualitiesOptions = this.buildBQTree();
        this.refreshBQTreeSelect();
    }

    public async exportAllTasks(): Promise<void> {
        if (this.isExportLoading || this.fiscalYear === "" || this.entityToExport === "" || this.quartersSelected.length === 0) {
            return;
        }

        const periods = [];
        this.quartersSelected.forEach(quarter => {
            periods.push({
                startDate: new Date(quarter.split(";")[0]),
                endDate: new Date(quarter.split(";")[1]),
            });
        });

        this.tasksExportFilter = {
            periods,
            entityId: parseInt(this.entityToExport, 10),
            brandQualityIds: this.searchBrandQualityIds,
            touchPointIds: this.searchTouchPointsIds,
            validationStatus: this.tasksExportFilter.validationStatus,
        };

        this.isExportModalOpened = false;

        const date: string = format(new Date(), "yyyy-MM-dd_hh-mm-ss");
        this.isExportLoading = true;

        // open notificaiton
        this.$notify({
            title: "Download in progress",
            text: `Extract_tasks_export_${date}.xlsx`,
            data: {
                actions: [{
                    title: "Cancel",
                    click: async () => {
                        await this.onCancelRequest();
                    },
                }],
            },
            type: "loader",
            duration: -1,
        });

        // get notification to close it when dowload will be done
        const notif = document.getElementsByClassName("progress-notif");
        // creating close event
        const event = document.createEvent("Event");
        event.initEvent("close", true, true);

        // create cancellation token
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        this.request = { cancel: source.cancel };

        const response = await campaignTasksClient.downloadAllTasks(`Extract_tasks_export_${date}.xlsx`, window.location.origin, this.tasksExportFilter, source);
        if (response?.status === 200) {
            const contentDispositionHeader: string = response.headers["content-disposition"];
            const fileName = parse(contentDispositionHeader);

            // close Notification
            for (const not of notif) {
                not.dispatchEvent(event);
            }

            fileDownload(response.data, fileName.parameters.filename);

            this.$notify({
                title: "Download complete",
                text: `Extract_tasks_export_${date}.xlsx`,
                data: { status: "success" },
            });
        }
        else if (response?.status !== 200) {
            // close Notification
            for (const not of notif) {
                not.dispatchEvent(event);
            }

            this.$notify({
                title: "Download failed",
                text: "Please try to reduce the scope or/and the period and relaunch the download",
                data: { status: "error" },
            });
        }

        this.isExportLoading = false;
    }

    public async onCancelRequest(): Promise<void>  {
        this.request.cancel();
        this.isExportLoading = false;
        this.$notify({
            title: "Download cancelled",
            text: "The request has been cancelled.",
            data: { status: "info" },
        });
    }

    public helpMenuEvent(menuItemName: string): void {
        analyticsService.onHelpMenuEvent(menuItemName);
    }

    public getRoleName(role: Role): string {
        switch (role) {
            case Role.None:
                return "-";

            case Role.Member:
                return "Member";

            case Role.DataCollector:
                return "Data Collector";

            case Role.LocalDataSteward:
                return "Local Data Steward";

            case Role.Administrator:
                return "Administrator";

            case Role.CountryAdministrator:
                return "Country Administrator";

            default:
                throw new UnreachableCaseError(role);
        }
    }

    // functions for export all tasks

    public getValidationStatusName(option: string): string {
        if (option === "validated") {
            return "Validated";
        }
        else if (option === "pending") {
            return "Pending";
        }
        else if (option === "not_completed") {
            return "Not completed";
        }
        else if (option === "rejected") {
            return "Rejected";
        }
    }

    public onValidationStatusFilterChange(value: string): void {
        if (value === "validated") {
            this.tasksExportFilter.validationStatus = [ValidationStatus.Validated];
        }
        else if (value === "pending") {
            this.tasksExportFilter.validationStatus = [ValidationStatus.Pending];
        }
    }

    /** Handle touchpoint select in treeselect */
    public onTouchPointSelect(touchPoint: PrTreeOption): void {
        const children = this.flattenPrTreeOption(touchPoint.children);
        this.searchTouchPointsIds.push(touchPoint.id);
        this.searchTouchPointsIds = [...new Set(this.searchTouchPointsIds.concat(children.map(tp => tp.id)))];
        if (touchPoint.id === 0) {
            const test = this.flattenPrTreeOption(this.availableTouchPoints);
            this.searchTouchPointsIds = [...new Set(this.searchTouchPointsIds.concat(test.map(tp => tp.id)))];
            this.searchTouchPointsIds = this.searchTouchPointsIds.concat(0);
            // this.disableTouchPointTreeOnSelectAll();
        }
        this.onTouchPointSearch("");
    }

    /** Handle touchpoint unselect in treeselect */
    public onTouchPointUnSelect(touchPoint: PrTreeOption): void {
        const children = this.flattenPrTreeOption(touchPoint.children);
        this.searchTouchPointsIds = this.searchTouchPointsIds.filter(x => x !== touchPoint.id && !children.map(tp => tp.id).includes(x));
        if (touchPoint.id === 0) {
            this.searchTouchPointsIds = [];
        }
        else if (this.searchTouchPointsIds.includes(0)) {
            this.searchTouchPointsIds = this.searchTouchPointsIds.filter(x => x !== 0);
        }
        this.onTouchPointSearch("");
        // this.searchTouchPointsIds = this.searchTouchPointsIds.filter(stp => stp !== touchPoint.id);
    }

    /** Handle touchpoint chip close in treeselect */
    public onTouchPointChipClose(touchPoint: PrTreeOption): void {
        if (touchPoint) {
            const currentTree = this.availableTouchPoints;
            const idsToFilter = this.getIdsToClose(currentTree, touchPoint.id);
            this.searchTouchPointsIds = this.searchTouchPointsIds.filter(tpId => !idsToFilter.some(id => id === tpId));
            this.unCheckChildren(touchPoint);
            this.refreshTouchPointsTreeSelect();
            this.onTouchPointSearch("");
        }
    }

    public onTouchPointSearch(name: string): void {
        this.touchPointFilter = name;
        this.availableTouchPoints = this.buildTouchPointsTree();
        this.refreshTouchPointsTreeSelect();
    }

    public onBrandQualitySearch(name: string): void {
        this.brandQualityFilter = name;
        this.availableBrandQualitiesOptions = this.buildBQTree();
        this.refreshBQTreeSelect();
    }

    /** Handle brand quality select in treeselect */
    public onBQSelect(node: PrTreeOption): void {
        if (node.key.startsWith("bq-")) {
            this.searchBrandQualityIds.push(node.id);
        }
        else {
            this.searchBrandQualityIds = this.searchBrandQualityIds.concat(node?.children?.map(c => c.id));
        }
        if (node.id === 0) {
            // push all brandQualities in searchBrandQualityIds
            this.searchBrandQualityIds = this.availableBrandQualitiesOptions?.flatMap(bq => bq.children).map(c => c.id);
            this.searchBrandQualityIds = this.searchBrandQualityIds.concat(0);
        }
        this.onBrandQualitySearch("");
    }

    /** Handle brand quality unselect in treeselect */
    public onBQUnSelect(node: PrTreeOption): void {
        if (node.key.startsWith("bq-")) {
            this.searchBrandQualityIds = this.searchBrandQualityIds.filter(sbq => sbq !== node.id);
        }
        else {
            this.searchBrandQualityIds = this.searchBrandQualityIds.filter(sbq => !node.children.some(c => c.id === sbq));
        }
        if (node.id === 0) {
            this.searchBrandQualityIds = [];
            this.onBrandQualitySearch("");
        }
        else if (this.searchBrandQualityIds.includes(0)) {
            this.searchBrandQualityIds = this.searchBrandQualityIds.filter(x => x !== 0);
            this.onBrandQualitySearch("");
        }
    }


    public onBQChipClose(node: PrTreeOption): void {
        if (node) {
            const idsToFilter = node?.children?.length > 0 ? node.children.map(c => c.id) : [node.id];
            this.searchBrandQualityIds = this.searchBrandQualityIds.filter(bqId => !idsToFilter.some(id => id === bqId));

            this.availableBrandQualitiesOptions = this.buildBQTree();
        }
    }

    /** Build touchpoints treeselect options, checking selected touchpoints
     *
     * @returns Treeselect options
     */
    protected buildTouchPointsTree(): PrTreeOption[] {
        if (!this.usesTouchPointTree || this.references == null || this.references.touchPointIds.length === 0) {
            return [];
        }

        const buildTree = (touchPoints: ITouchPoint[]) => touchPoints
            .reduce((acc, touchPoint) => {
                const node = this.getTouchPointNode(touchPoint);

                if (touchPoint.children) {
                    node.children = buildTree(touchPoint.children);
                }
                else {
                    node.children = null;
                }

                if (this.references.touchPointIds.includes(touchPoint.touchPointId)) {

                    if (this.searchTouchPointsIds.includes(touchPoint.touchPointId)) {
                        node.checked = true;
                    }

                    if (touchPoint.children?.length === 0) {
                        node.children = null;
                    }
                    if (this.touchPointFilter?.length > 0 ) {
                        if (touchPoint.name.toLocaleLowerCase().includes(this.touchPointFilter.toLocaleLowerCase()) || node.checked === true) {
                            acc.push(node);
                        }
                    }
                    else {
                        acc.push(node);
                    }
                }
                else if (node.children.length > 0 && touchPoint.isActive) {
                    acc.push(node);
                }
                else if (touchPoint.children?.length > 0 && !touchPoint.isActive) {
                    node.children.forEach(element => {
                        element.parentKey = node.parentKey;
                        acc.push(element);
                    });
                }

                return acc;
            }, [] as PrTreeOption[]);

        const hierarchy: ITouchPoint[] = store.getters["data/touchPointsHierarchy"];
        const result = buildTree(hierarchy);
        result.unshift({
            id: 0,
            key: "0",
            parentKey: null,
            label: "Select all",
            checkbox: true,
            checked: this.searchTouchPointsIds.includes(0),
            children: null,
        });

        return result;
    }

    protected buildBQTree(): PrTreeOption[] {
        const brands: IBrand[] = store.getters["data/brandsHierarchy"] ?? [];
        let tree = brands
        .filter(b => b.isActive)
        .map(b => this.brandToTreeOptions(b))
        .filter(b => b.children?.some(c => this.references.brandQualityIds.some(bq => bq === c.id)));

        tree.unshift({
            key: "bq-" + 0,
            parentKey: null,
            id: 0,
            label: "Select all",
            checkbox: true,
            checked: this.searchBrandQualityIds.includes(0),
            children: [],
        });

        if (this.brandQualityFilter) {
            tree = tree.filter(b => b.label.toLocaleLowerCase().includes(this.brandQualityFilter.toLocaleLowerCase()));
        }

        return tree;
    }

    protected refreshTouchPointsTreeSelect(): void {
        if (this.usesTouchPointTree) {
            this.$nextTick(() => this.$refs.touchPointTreeSelect?.updateOptions());
        }
    }

    protected refreshBQTreeSelect(): void {
        if (this.usesBQTree) {
            this.$nextTick(() => this.$refs.bqTreeSelect?.updateOptions());
        }
    }

    /** Build a treeselect option from a touchpoint
     *
     * @param touchPoint Touchpoint definition
     * @returns Treeselect option item
     */
    private getTouchPointNode(touchPoint: ITouchPoint): PrTreeOption {
        return {
            id: touchPoint.touchPointId,
            key: touchPoint.touchPointId?.toString(),
            parentKey: touchPoint.parentTouchPointId?.toString(),
            label: touchPoint.name,
            checkbox: true,
            checked: this.searchTouchPointsIds.includes(touchPoint.touchPointId),
            children: null,
        };
    }

    private findTopNode(node: PrTreeOption): number[] {
        let result = [];
        if (node.checked && !node.indeterminate) {
            result.push(node.id);
        }
        else if (node.children) {
            node.children.forEach(c => result = result.concat(this.findTopNode(c)));
        }
        return result;
    }

    private flattenPrTreeOption(options: PrTreeOption[]): PrTreeOption[] {
        let result = [];
        if (!options) {
            return result;
        }

        for (const option of options) {
            if (option.children?.length > 0) {
                result.push(option);
                result = result.concat(this.flattenPrTreeOption(option.children));
            }
            else {
                result.push(option);
            }
        }

        return result;
    }

    private unCheckChildren(node: PrTreeOption): void {
        if (node) {
            node.checked = false;
            node.indeterminate = false;
            if (node.children) {
                node.children.forEach(c => this.unCheckChildren(c));
            }
        }
    }

    private getIdsToClose(tree: PrTreeOption[], tpId: number): number[] {
        let ids = [];

        for (const node of tree) {
            if (node.id === tpId) {
                ids.push(node.id);
                ids = ids.concat(this.flattenPrTreeOption(node.children).map(c => c.id));
            }
            else if (node.children) {
                ids = ids.concat(this.getIdsToClose(node.children, tpId));
            }
        }

        return ids;
    }

    private brandToTreeOptions(brand: IBrand): PrTreeOption {
        const option: PrTreeOption = {
            key: "b-" + brand.brandId,
            parentKey: null,
            id: brand.brandId,
            label: brand.name,
            checkbox: true,
            children: this.brandQualitiesToTreeOptions(brand.brandQualities),
        };

        // Set brand parent as check or indeterminate when children are selected
        // DS doesn't do it on it's own
        if (option.children?.length > 0) {
            const checkedChilren = option.children?.filter(ch => ch.checked).length ?? 0;
            if (checkedChilren === option.children.length) {
                option.checked = true;
            }
            else if (checkedChilren > 0) {
                option.checked = true;
                option.indeterminate = true;
            }
        }

        return option;
    }

    private brandQualitiesToTreeOptions(brandQualities: IBrandQuality[]): PrTreeOption[] {
        return brandQualities
            .filter(bq => bq.isActive && this.references.brandQualityIds.some(bqId => bq.brandQualityId === bqId))
            .map(bq => ({
                key: "bq-" + bq.brandQualityId,
                parentKey: "b-" + bq.brandId,
                id: bq.brandQualityId,
                parentId: bq.brandId,
                label: bq.name,
                checkbox: true,
                checked: this.searchBrandQualityIds.includes(bq.brandQualityId),
            }));
    }
    // end functions export all tasks


    private selectEntity(entity: IEntity): void {
        store.dispatch("account/setSelectedEntity", entity.entityId);
        store.dispatch("data/fetchTouchpointMetricExceptions", entity.entityId);
        localStorage.setItem(this.localStorageSelectedEntityKey, JSON.stringify(this.selectedEntity.entityId));
    }

    private redirect(routeName: string): void {
        const route = this.$router.getRoutes().find(r => r.name === routeName);
        if (this.$route.name !== route.name) {
            this.$router.replace(route);
        }
    }

    private initializeSelectedEntity(entities: IEntity[]): void {
        if (!this.selectedEntity) {
            const storedSelectedId = parseInt(localStorage.getItem(this.localStorageSelectedEntityKey), 10);

            if (storedSelectedId && entities.some(e => e.entityId === storedSelectedId)) {
                store.dispatch("account/setSelectedEntity", storedSelectedId);
                store.dispatch("data/fetchTouchpointMetricExceptions", storedSelectedId);
            }
            else {
                store.dispatch("account/setSelectedEntity", entities[0]?.entityId);
                store.dispatch("data/fetchTouchpointMetricExceptions", entities[0]?.entityId);
            }
        }
    }
}

type Refs = Vue["$refs"] & {
    touchPointTreeSelect: PrTreeselect;
    bqTreeSelect: PrTreeselect;
};

interface IBrandQualityChip {
    id: number;
    isBrandLevel: boolean;
}
